<template >
  <div>
    <Navigator />
    <div class="politics-body">
      <div class="wpb_wrapper">
        <p><strong>AVISO DE PRIVACIDAD</strong></p>
        <p>
          Revista Foodie. (en lo sucesivo, el “<u>Responsable</u>”), con
          domicilio en Oviedo No.9, Colonia. Las Granjas. Cp. 83250. Hermosillo,
          Sonora, Estados Unidos Mexicanos, en cumplimiento con la Ley Federal
          de Protección de Datos Personales en Posesión de Particulares y su
          Reglamento (en lo sucesivo de&nbsp; manera conjunta, la “<u>Ley</u>”),
          pone a su disposición el presente Aviso de Privacidad, con la
          finalidad de que conozca de qué manera se usan, divulgan y almacenan
          sus datos personales y cualquier información que se recabe a través de
          distintos medios incluyendo nuestro sitio web, a efecto de garantizar
          la privacidad de dicha información y el derecho a la autodeterminación
          informativa de las personas. Asimismo, hace de su conocimiento las
          finalidades por las que el Responsable recaba y trata sus Datos
          Personales (según dicho término se define más adelante).
        </p>
        <p>
          El Responsable recabará los siguientes datos personales: Nombre, fecha
          de nacimiento, género, domicilio, teléfono, correo electrónico, nombre
          de la empresa, antecedentes de la empresa, información de la empresa y
          datos complementarios de la empresa (en lo sucesivo, los “Datos
          Personales”), necesarios para los siguientes fines: Procesar y
          utilizar la información para fines de prospección.
        </p>
        <p>
          Asimismo, sus Datos Personales serán utilizados para los siguientes
          fines secundarios, que nos permiten y facilitan brindarle un mejor
          servicio y/o atención: Estudios de mercado y envío de ofertas y/o
          promociones.
        </p>
        <p>
          El Responsable, conforme a lo establecido por la Ley, hace de su
          conocimiento, mediante el presente aviso, que sus Datos Personales
          también serán utilizados para fines mercadotécnicos, publicitarios y/o
          de prospección comercial.
        </p>
        <p>
          El Responsable se compromete a que todos los Datos Personales
          obtenidos serán tratados bajo las más estrictas medidas de seguridad
          que garanticen su confidencialidad.
        </p>
        <p>
          Sus Datos Personales serán utilizados exclusivamente para los fines
          mencionados en el presente Aviso de Privacidad. En caso de que usted
          no desee que se traten sus Datos Personales para los fines que no sean
          estrictamente necesarios para brindarle el servicio y/o atención
          solicitados, entre ellos publicidad o envío de ofertas, usted lo podrá
          comunicar al Responsable a través de la siguiente información de
          contacto:
        </p>
        <p>
          Celular: 662 948 8430<br />
          Horario de atención: Lunes a Viernes de 08:00 a 19:00<br />
          Correo electrónico:
          <a href="mailto:contacto@revistafoodie.com">contacto@revistafoodie.com</a>
        </p>
        <p>
          De conformidad con la Ley, requerimos de su consentimiento expreso
          para el tratamiento de sus Datos Personales sensibles así como de
          carácter financiero y patrimonial, salvo por las excepciones que
          establece la Ley. Si usted manifiesta su consentimiento para el
          tratamiento de sus Datos Personales sensibles así como de carácter
          financiero y patrimonial, el Responsable se compromete a no tratar
          estos Datos Personales para finalidades distintas de las establecidas
          en este Aviso de Privacidad.
        </p>
        <p>
          Usted tiene derecho de acceder a sus Datos Personales que poseemos y a
          los detalles de su tratamiento, así como a rectificarlos en caso de
          ser inexactos o incompletos, o bien, cancelarlos cuando considere que
          no se requieren para alguna de las finalidades señaladas en el
          presente Aviso de Privacidad, estén siendo utilizados para finalidades
          no consentidas o haya finalizado la relación contractual o de
          servicio, o bien, oponerse al tratamiento de los mismos para fines
          específicos (en lo sucesivo, los “Derechos ARCO”).
        </p>
        <p>
          Para el ejercicio de los Derechos ARCO, usted deberá presentar la
          solicitud respectiva, a través de la siguiente información de
          contacto: Celular: 662 948 8430 Horario de atención: Lunes a Viernes
          de 08:00 a 19:00<br />
          Correo electrónico:
          <a href="mailto:contacto@revistafoodie.com">contacto@revistafoodie.com</a>
        </p>
        <p>
          La solicitud que presente para el ejercicio de los Derechos ARCO
          deberá indicar su nombre y el domicilio o correo electrónico en el que
          se desea recibir toda la información relacionada con el trámite,
          además de estar acompañada del o los documentos con los que se
          acredite su identidad o la personalidad de su representante legal.
          Asimismo, le agradeceremos incluir una descripción clara y precisa de
          los datos respecto de los que se busca ejercer alguno de los Derechos
          ARCO y de cualquier otro elemento que nos permita identificar o
          localizar los Datos Personales en cuestión.&nbsp;Cuando se trate de
          “rectificación” de sus Datos Personales, deberá indicarnos también
          cuál es la modificación exacta que busca realizar y presentar la
          documentación que soporte la petición.
        </p>
        <p>
          Adicionalmente, por su protección y beneficio, es posible que le
          solicitemos documentación adicional que nos permita identificar con
          plenitud los Datos Personales que desee acceder, rectificar y/o
          cancelar, o aquellos respecto de los cuales usted desee oponerse a su
          tratamiento. El Responsable procurará que los Datos Personales
          contenidos en las bases de datos sean pertinentes, correctos y
          actualizados para los fines para los cuales fueron recabados y cuando
          hayan dejado de ser necesarios para el cumplimiento de las finalidades
          previstas, deberán ser cancelados. No obstante lo anterior, es posible
          que el Responsable no pueda cancelar o bloquear sus Datos Personales
          de conformidad con lo dispuesto por la Ley.
        </p>
        <p>
          Una vez recibida su solicitud completa y debidamente integrada, el
          Responsable le comunicará la respuesta correspondiente en un plazo
          máximo de 10 días hábiles. Si resultare procedente, dentro de los
          siguientes 15 días hábiles podrá hacer efectivo el derecho solicitado.
          En caso de que el Responsable deba hacer entrega de documentación como
          resultado del derecho ejercido, lo hará a través de copias que le
          serán enviadas por correo electrónico o las pondrá a su disposición en
          el domicilio que al efecto le indique, dentro del plazo antes
          mencionado, para que usted pueda recogerlas directamente o a través de
          tercero facultado mediante carta poder.
        </p>
        <p>
          Usted puede en cualquier momento revocar el consentimiento que nos ha
          otorgado para impedir total o parcialmente el tratamiento de sus Datos
          Personales, sin embargo, es importante que tenga en cuenta que no en
          todos los casos podremos atender su solicitud o concluir el uso de
          forma inmediata, ya que es posible que por alguna obligación legal el
          Responsable requiera seguir tratando sus Datos Personales. Asimismo,
          usted deberá considerar que para ciertos fines, la revocación de su
          consentimiento implicará que no le podamos seguir prestando el
          servicio y/o atención solicitada, o la conclusión de su relación con
          el Responsable.&nbsp; En caso de que desee revocar su consentimiento
          al tratamiento de sus Datos Personales, deberá presentar su solicitud
          ante el Responsable acompañada de copia de su identificación oficial
          para acreditar que es el titular de los Datos Personales (en su caso,
          la autorización de su representante legal), y señalar el correo
          electrónico o domicilio al que se desea recibir la información
          relacionada con el trámite, una descripción clara y precisa de los
          Datos Personales respecto de los cuales requiere revocar su
          consentimiento y los datos que nos permitan notificarle la resolución
          recaída a su solicitud. El Responsable le notificará la resolución
          correspondiente a su solicitud dentro de los 10 días hábiles
          siguientes a la presentación de su solicitud, siempre que ésta se
          encuentre completa y debidamente integrada.
        </p>
        <p>
          Con el objeto de que usted pueda limitar el uso y divulgación de su
          información personal, puede inscribirse en el Registro Público para
          Evitar Publicidad, que está a cargo de la Procuraduría Federal del
          Consumidor (PROFECO), con la finalidad de que sus Datos Personales no
          sean utilizados para recibir publicidad o promociones, para mayor
          información sobre este registro, usted puede consultar el portal de
          Internet de la PROFECO.
        </p>
        <p>
          El Responsable, por medio del presente Aviso de Privacidad, le informa
          que en su página de Internet utiliza cookies y/u otras tecnologías a
          través de las cuales es posible monitorear su comportamiento como
          usuario de Internet, brindarle un mejor servicio y experiencia de
          usuario al navegar en nuestra página, así como ofrecerle nuevos
          productos y servicios basados en sus preferencias. Estas tecnologías
          pueden deshabilitarse siguiendo los pasos que para dichos fines se
          establecen en nuestra página de Internet.
        </p>
        <p>
          En cualquier momento el Responsable puede hacer modificaciones o
          actualizaciones al presente Aviso de Privacidad derivadas de nuevos
          requerimientos legales, de las políticas o prácticas de privacidad del
          Responsable o de sus necesidades derivadas de los productos o
          servicios que ofrece, o por cualquier otra causa.
        </p>
        <p>
          El Responsable hará de su conocimiento cualquier cambio o
          actualización al presente aviso a través de la página de Internet.
        </p>
        <p>
          Última actualización:<br />
          15/10/2019
        </p>
      </div>
    </div>
    <Footer />

  </div>
</template>
<script>
import Navigator from "../components/items-page/Navigator";
import Footer from "../components/items-page/Footer";

export default {
  name: "Home",
  components: {
    Navigator,
    Footer,
  },
};
</script>
<style >
.politics-body .wpb_wrapper {
    width: 85.507vw;
    margin: auto;
}
.wpb_wrapper p {
    margin: 6vw 0;
}
@media (min-width:768px) {
    
.politics-body .wpb_wrapper {
    width: 96.354vw;
    margin: auto;
}
.wpb_wrapper p {
    margin: 1.2vw 0;
}
}
</style>